/* eslint-disable */
import { createContext, useContext, useState, useEffect, useRef } from "react";
/*eslint no-unused-vars: "error"*/
import { PublicKey } from "@solana/web3.js";
import { MetaplexContext } from "./MetaplexContextProvider";
import { fetchAuctionHouse } from "../Api/Solana/auctionHouse";
import { getAllNfts } from "../Api/Solana/getAllNfts";
import { solToUSD } from "../contexts/Coingecko";
import { listingByAddress } from "../Adapter/Solana/listingByAddress";
import { myNftAdapter } from "../Adapter/Solana/nft";
import { getNFTS } from "../Api/Solana/getAllNfts";

export const AuctionHouseContext = createContext();

const prodAuctionHouse = "5nHM2fhir19nk2hXK1fQogYqbDym4sivdWAAurZGSQax"; //production
const devAuctionHouse = "Dw2gUXGGVJn26dhkszEZBV2tejZJgFJFXgoGmg5fJ4F7"; //development

const AuctionHouseContextProvider = ({ children }) => {
  const AUCTION_HOUSE_ADDRESS = new PublicKey(prodAuctionHouse);

  const AUCTION_HOUSE_PROGRAM_ID = new PublicKey(
    "hausS13jsjafwWwGqZTUQRmWyvyxn9EQpqMwV1PBBmk"
  );

  const { metaplex, connection } = useContext(MetaplexContext);
  const isInitialMount = useRef(true);
  const [auctionHouse, setAuctionHouse] = useState(null)
  const [loading, setLoading] = useState(true);
  const [loadingAuctions, setLoadingAuctions] = useState(false);
  const [listedNfts, setListedNfts] = useState([]);
  const [collectionNfts, setCollectionNfts] = useState([]);
  const [listedMintAddress, setListedMintAddress] = useState([]);

  const [timeInterval, setTimeInterval] = useState(0);

  const [solPrice, setSolPrice] = useState(0);
  /*eslint no-unused-vars: "error"*/

  const [solTPS, setSolTPS] = useState(0);
  /*eslint no-unused-vars: "error"*/

  const [network, setNetwork] = useState([]);

  const [myAccounts, setAccounts] = useState([]);

  // useEffect(() => {

  //   setTimeout(() => {
  //     setTimeInterval(timeInterval + 1);
  //     // console.log("refreshing listings")

  //   }, 100000); //20000);
  // }, [timeInterval])

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);


  }, 100000); //20000);

  const removeListedNft = (tradeStateAddress) => {
    setListedNfts(
      listedNfts.filter((item) => item.tradeStateAddress !== tradeStateAddress)
    );
  };
  const getNetworkStatus = async () => {
    const status = navigator.connection;
    setNetwork(status);
  };

  const getSolanaTPS = async () => {
    // get solana tps from connection
    metaplex.connection
      .getRecentPerformanceSamples(1)
      .then((data) => {
        // console.log(
        //   parseInt(data[0].numTransactions / data[0].samplePeriodSecs),
        //   "solana TPS"
        // );
        setSolTPS(parseInt(data[0].numTransactions / data[0].samplePeriodSecs));
      })
      .catch(() => setSolTPS("NA"));
  };



  const getListings = async () => {

    try {
      const retrieveTradeStates = await metaplex
        .auctionHouse()
        .findListings({
          auctionHouse: auctionHouse
        })

      // console.log(retrieveTradeStates, 'retrieveListing');


      retrieveTradeStates.forEach(async retrieveTradeState => {
        // console.log(retrieveTradeState.tradeStateAddress.toString(), "retrieve tradeStateAddress");


        // new way
        setTimeout(async () => {
          const retrieveListing = await metaplex
            .auctionHouse()
            .loadListing({ lazyListing: retrieveTradeState })





          // console.log(retrieveListing, 'retrieveListing');


          // end





          // old way

          // const retrieveListing = await metaplex
          //   .auctionHouse().findListingByTradeState({
          //     tradeStateAddress: retrieveTradeState.tradeStateAddress,
          //     auctionHouse: auctionHouse,
          //   });


          // end

          if (
            retrieveListing.asset.creators.length &&
            retrieveListing.purchaseReceiptAddress === null &&
            retrieveListing.canceledAt == null &&
            // sale with no purchase receipt
            retrieveListing.tradeStateAddress !=
            "3DXe5VSxZ3LphBXak9xmoBK6uJhkQ6yuWmYRwThPLWjP"
          ) {

            setListedNfts((listedNfts) => [
              ...listedNfts,
              listingByAddress(retrieveListing),
            ]);
          }


          if (
            retrieveListing.purchaseReceiptAddress === null &&
            retrieveListing.canceledAt === null
          ) {
            const mintAddress = retrieveListing?.asset?.collection?.address?.toString();

            // const findCollectionNft = await metaplex.nfts().findByMint({ mintAddress })

            // mintAddress &&
            // setCollectionNfts((collectionNfts) =>
            //   [...collectionNfts, findCollectionNft].filter(
            //     (v, i, a) =>
            //       a.findIndex((v2) =>
            //         ["name"].every((k) => v2[k] === v[k])
            //       ) === i
            //   )
            // );

            var requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };
            if (mintAddress !== undefined) {
              fetch(`https://api.nft-plus.me/nft/get_nft/?mint_id=${mintAddress}&update_new=false`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  // console.log(result, "nft from mongo")
                  const findCollectionNft = result.nft
                  mintAddress && setCollectionNfts(collectionNfts => [...collectionNfts, findCollectionNft].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i))
                })
                .catch(error => console.log('nft metadata from mongo error:', error));
            }

          }
        }, 2000); //20000);
      })
    } catch (error) {
      // console.error('Error fetching NFTs', error);
    }

  }


  const getAuctionHouse = async () => {

    try {
      const auction = await fetchAuctionHouse({
        metaplex,
        AUCTION_HOUSE_ADDRESS,
      });
      setAuctionHouse(auction);
      console.log(auction, "auction house")
      console.log(auctionHouse, "auction house")

      setLoading(false);
      setLoadingAuctions(true);
      timeInterval === 0 && setTimeInterval(1);
      // getListings()


      // const accounts = await getAllNfts({
      //   metaplex,
      //   auction: auction,
      //   programId: AUCTION_HOUSE_PROGRAM_ID,
      //   filterString: AUCTION_HOUSE_ADDRESS,
      //   connection
      // });

      // setAccounts((myAccounts) => [...myAccounts, accounts]);

      // for (let i = 0; i < accounts.length; i++) {
      //   // console.log(accounts[i].account.data, "account data")
      //   const tradeState = new PublicKey(
      //     accounts[i]?.account?.data?.slice(8, 8 + 32)
      //   );
      // }

      if (listedNfts.length) {
      }
      if (listedNfts.length > 8) {
        setLoadingAuctions(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadMyNFT = async () => {
    let conversionPrice = await solToUSD();
    if (conversionPrice !== 4.04) {
      setSolPrice(conversionPrice);
    }
  };
  useEffect(() => {
    getSolanaTPS();
    getNetworkStatus();

    if (auctionHouse === null || auctionHouse === undefined) {
      getAuctionHouse();

    }
    else {
      listedNfts.length && setListedNfts([])
      listedNfts.length === 0 && getListings()
    }

    loadMyNFT();
    return () => {
      getAuctionHouse()
      getListings()
      getSolanaTPS();
      getNetworkStatus();
      loadMyNFT();

    }
  }, [timeInterval]);


  // useEffect(() => {
  //   listedNfts.length === 0 && getAuctionHouse();
  //   listedNfts.length === 0 && getListings()
  //   return () => {
  //     getAuctionHouse();
  //     getListings()
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     listedNfts.length === 0 && getAuctionHouse();
  //     listedNfts.length === 0 && getListings()
  //     return () => {
  //       getAuctionHouse();
  //       getListings()
  //     }
  //   }
  // }, []);



  useEffect(() => {
    const mintList = listedNfts.map((item) => item.mintAddress.toString());
    setListedMintAddress(mintList);
  }, [listedNfts]);


  return (
    <AuctionHouseContext.Provider
      value={{
        auctionHouse,
        loading,
        loadingAuctions,
        listedNfts,
        removeListedNft,
        collectionNfts,
        listedMintAddress,
        solPrice,
        solTPS,
        network,
      }}
    >
      {children}
    </AuctionHouseContext.Provider>
  );
};
export default AuctionHouseContextProvider;
