import { useEffect } from "react";
import $ from "jquery";
import { Routes, Route } from "react-router-dom";
import Activity from "./pages/Activity";
import AuthorProfile from "./pages/AuthorProfile";
import CompanyProfile from "./pages/CompanyProfile";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Category from "./pages/Category";
import Collections from "./pages/Collections";
import CreateItem from "./pages/CreateItem";
import Creators from "./pages/Creators";
import Explore from "./pages/Explore";
import Home from "./pages/Home";
import LoginRegister from "./pages/LoginRegister";
import MarketSingle from "./pages/MarketSingle";
import NftLiveBidding from "./pages/NftLiveBidding";
import Ranking from "./pages/Ranking";
import SolanaWalletProvider from "./Providers/WalletProvider";
/* eslint-disable */
import Metaverse from "./pages/Metaverse";

import MetaplexContextProvider from "./Providers/MetaplexContextProvider";
import AuctionHouseContextProvider from "./Providers/AuctionHouseContextProvider";
import MyNFTs from "./pages/MyNFTs";
import { SignUp } from "./pages/SignUp";
import ApplyCollection from "./Components/Common/Collection/ApplyCollection";
import SelectType from "./Components/Common/Collection/SelectType";
import PhantomWallet from "./pages/PhantomWallet";
import MintItem from "./pages/MintItem";
import ViewNFTDetails from "./pages/ViewNFTDetails";
import 'sweetalert2/dist/sweetalert2.css'
import PageNotFound from "./pages/PageNotFound";
import cookies from 'js-cookie';
import { ViewCollection } from "./pages/ViewCollection";
import DetailCollection from "./pages/DetailCollection";
import AdminDashboard from "./pages/AdminDashboard";
import { Notification } from "./Components/Common/Notification/Notification";

const languages = [
  {
    code: 'ar',
    name: 'عربى',
    country_code: 'ae',
    // dir: "rtl",
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb'
  },
];

function App() {
  const currentLanguageCode = localStorage.getItem('i18nextLng') || cookies.get('i18next') || 'en';
  const currentLanguage = languages.find(lng => lng.code === currentLanguageCode);

  useEffect(() => {
    $(".menu-trigger").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").removeClass("active");
    });
  }, []);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    const style = document.getElementById('style-direction');
    const respStyle = document.getElementById('resp-style-direction');
    // if (currentLanguageCode === 'ar') {
    //   style.href = 'assets/css/arabic.css';
    //   respStyle.href = 'assets/css/responsive_ar.css';
    // } else {
    style.href = 'assets/css/style.css';
    respStyle.href = 'assets/css/responsive.css';
    // }
  }, [currentLanguage])
  return (
    <SolanaWalletProvider>
      <MetaplexContextProvider>
        <AuctionHouseContextProvider>
          {/* <Notification /> */}
          {/* <WalletMultiButton /> */}
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/my-nfts" element={<MyNFTs />} />
              <Route path="/my-nfts/view-nft/:id" element={<ViewNFTDetails view={"myNfts"} />} />
              <Route path="/view-nft/:id" element={<ViewNFTDetails />} />
              <Route path="/mint-nft" element={<MintItem />} />
              <Route path="/nft-marketplace" element={<Explore />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/blog" element={<Blog />} />
              {/* <Route path="/signup" element={<Explore />} /> */}
              <Route path="/signup" element={<SignUp />} />
              <Route path="/select-type" element={<SelectType />} />
              <Route path="/apply" element={<ApplyCollection />} />
              {/* <Route path="/notifications" element={<Notification />} /> */}

              <Route path="/blog-details" element={<BlogDetails />} />
              <Route path="/phantomwallet" element={<PhantomWallet />} />

              <Route path="/activity" element={<Activity />} />
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/login-register" element={<LoginRegister />} />
              <Route path="/author-profile/:id" element={<AuthorProfile />} />
              <Route path="/author-profile/view-nft/:id" element={<ViewNFTDetails view={"userProfile"} />} />
              <Route path="/company-profile/:id" element={<CompanyProfile />} />
              <Route path="/create-item" element={<CreateItem />} />
              <Route path="/category" element={<Category />} />
              <Route path="/creators" element={<Creators />} />
              <Route path="/market-single" element={<MarketSingle />} />
              <Route path="/nft-live-bidding" element={<NftLiveBidding />} />
              <Route path="/view-collection" element={<ViewCollection />} />
              <Route path="/detail-collection/:id" element={<DetailCollection />} />
              <Route path="/metaverse" element={<Metaverse />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/admin" element={<AdminDashboard />} />
            </Routes>
          </div>
        </AuctionHouseContextProvider>
      </MetaplexContextProvider>
    </SolanaWalletProvider>
  );
}

export default App;
