import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SingleMarketLoader = (props) => {
    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">


            <section className="market-single-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="market-single-img">
                                <span>
                                    {props.nft?.address ? <img src={props.nft?.json?.image} alt="" /> : <Skeleton height="450px" />}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="market-single-top" style={{ display: 'flex' }}>
                                <div className="market-single-title-wrap" >
                                    <h2 className="title" > {props.nft?.address ? props.nft?.json?.name : <Skeleton style={{ width: '300px' }}></Skeleton>}</h2>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <h3><Skeleton width="200px" /></h3>{" "}
                                </div>
                            </div>

                            {props?.nft?.address ?

                                <div className="highest-bid-wrap">

                                    <div>
                                        <h5>Description</h5>
                                    </div>
                                    <div className="market-single-content">
                                        <p>{props?.nft?.json?.description}</p>
                                    </div>
                                </div> :
                                <Skeleton className="highest-bid-wrap" height=" 150px" />}

                            <div className="row" style={{ "marginTop": "20px" }}>
                                <div className="col-sm-6">


                                    <Skeleton className="market-single-creator" width="250px" height="75px" />

                                </div>
                                <div className="col-sm-6">
                                    <Skeleton className="market-single-creator" width="100%" height="100px" />


                                </div>

                            </div>

                            <Skeleton className="place-bid-btn-loader" borderRadius={'30px'} />
                        </div>
                    </div>
                </div>
            </section>
        </SkeletonTheme>

    );
};

export default SingleMarketLoader;
