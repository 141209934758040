import React, { useState, useContext } from "react";
import NFTImageUploader from "./ImageUploader";
import NFTForm from "./NFTForm";
import "./CreateNFT.css";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { createNft } from "../../../Api/Solana/nft";
import { NFTMintedSuccess } from "./NFTMintedSuccess";
import Swal from 'sweetalert2/dist/sweetalert2.js'



const CreateNFT = () => {
  const metaplex = useContext(MetaplexContext);
  const [file, setFile] = useState(undefined);
  const [url, setUrl] = useState(undefined);

  const [minting, setMinting] = useState(false);
  const [mintedSuccessfully, setMintedSuccessfully] = useState(false);

  const [createdNFT, setCreatedNFT] = useState({});



  const mintAgain = () => {
    console.log("mint again");
    setUrl(undefined);
    setFile(undefined);
    setMinting(false);
    setMintedSuccessfully(false);
  };

  const fileChangeHandler = (event) => {
    setFile(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0]));
    // incrementStep();
  };

  const removeFile = () => {
    setFile(undefined);
    setUrl(undefined);
  };
  const onSubmit = async (data) => {
    // incrementStep();

    // setOpenModal(true);


    if (metaplex.publicKey) {
      setMintedSuccessfully(false);
      var metaData = {
        name: data.name,
        symbol: data.symbol,
        description: data.description,
        seller_fee_basis_points: data.royalty * 100,
        image: file,
        attributes: [],
        properties: {
          creators: [{ address: metaplex.publicKey, share: "100" }],
        },
      };
      setMinting(true);
      try {
        const nft = await createNft(metaplex, metaData);
        console.log("Minted NFT", nft?.mint?.address.toBase58());

        setMintedSuccessfully(true);
        setCreatedNFT(nft);
      } catch (error) {
        setMinting(false);
        setMintedSuccessfully(false);
      }
      setMinting(false);
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Wallet is not detected, Please connect your wallet!',
        icon: 'error',
        confirmButtonText: 'Close'
      })
      console.error("Please auth befor login");
    }
  };

  return (
    <>
      {/* <div style={{ padding: "0px 0 90px" }}> */}
      <div style={{ minHeight: 500 }}>
        {mintedSuccessfully ? (
          <NFTMintedSuccess mintAgain={mintAgain} mintedNFT={createdNFT} />
        ) : !url ? (
          <NFTImageUploader handleFileChange={fileChangeHandler} />
        ) : (
          <div className="container m-auto row">
            <div className="col-md-5">
              <div
                className="position-relative mb-5"
                style={{ width: "fit-content", margin: "auto" }}
              >
                <button onClick={removeFile} className="nft-remove-btn">
                  X
                </button>
                <img
                  src={url}
                  className="nft-image-preview"
                  alt="Selected NFT"
                />

              </div>

            </div>


            <div className="col-md-7" style={{ paddingLeft: "48px" }}>
              <NFTForm minting={minting} onSubmit={onSubmit} />

            </div>


          </div>
        )}

      </div>
    </>
  );
};

export default CreateNFT;
