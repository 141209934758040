import { useContext, useEffect, useState } from "react";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import MyNFTLoader from "../../Common/MyNFTs/MyNFTLoader";
import { Link } from "react-router-dom";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { solToUSD } from "../../../contexts/Coingecko";
import Marquee from "react-fast-marquee";
import { Identicon } from "../Nft/Identicon";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const LatestProduct = () => {
    const { loadingAuctions, listedNfts } = useContext(AuctionHouseContext);
    // const [ readyNFTs, setReadyNFTs ] = useState([]);
    // const [popNFTs, setPopNFTs] = useState(false);
    const { publicKey } = useContext(MetaplexContext);
    const metaplex = useContext(MetaplexContext);

    const [solPrice, setSolPrice] = useState(0);

    const { t } = useTranslation();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1500 }, //min:2000
            items: 4,
            slidesToSlide: 4,
        },
        desktop: {
            breakpoint: { max: 1500, min: 993 }, //max:2000
            items: 3,
            slidesToSlide: 3,//4,
        },
        // smallDesktop: {
        //   breakpoint: { max: 2000, min: 1024 },
        //   items: 2.5,
        //   slidesToSlide: 4,
        // },
        largeTablet: {
            breakpoint: { max: 992, min: 650 }, //max:1024
            items: 2,
            slidesToSlide: 2,
        },
        tablet: {
            breakpoint: { max: 650, min: 464 },
            items: 1.5,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,

        }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="fa fa-angle-right"
            aria-label="Go to next slide"
            type="button"
            style={{
                position: 'absolute',
                outline: 'none',
                transition: 'all 0.5s',
                borderRadius: '5px',//'35px',
                zIndex: 10, //1000,
                border: 'none',
                background: 'rgba(0, 0, 0, 0.5)',
                minWidth: '43px',
                minHeight: '43px',
                opacity: 1,
                cursor: 1,
                right: 'calc(4% + 1px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: "white",//"#E039FD" //"#6345ED",
            }} onClick={() => onClick()} >
            {/* <i className="fa-solid fa-chevron-right" height="30px" width="30px" style={{  }} /> */}
            {/* <i className="fa fa-angle-right" aria-hidden="true" style={{ height: 40 }} /> */}
        </button>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="fa fa-angle-left"
            aria-label="Go to previous slide"
            type="button"
            style={{
                position: 'absolute',
                outline: 'none',
                transition: 'all 0.5s',
                borderRadius: '5px',
                zIndex: 10,
                border: 'none',
                background: 'rgba(0, 0, 0, 0.5)',
                minWidth: '43px',
                minHeight: '43px',
                opacity: 1,
                cursor: 1,
                left: 'calc(4% + 1px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: "white"
            }} onClick={() => onClick()} >
        </button>;
    };

    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }
    var aDay = 24 * 60 * 60 * 1000;
    useEffect(() => {
        const loadMyNFT = async () => {
            let conversionPrice = await solToUSD();
            if (conversionPrice !== 404) {
                setSolPrice(conversionPrice);
            }
            //  console.log("Solana Price: ", solPrice);
        };


        // if (listedNfts.length >= 3) {
        //   setPopNFTs(true);
        // }
        // call the function
        loadMyNFT()
            // make sure to catch any error
            .catch(() => {
                console.log('error')
            });
    }, [metaplex]);




    return (
        <section className="explore-products-area">
            <div className="container" >
                <div >
                    <div className="row mb-35">
                        <div className="col-md-7 col-sm-8" >
                            <div className="section-title" >
                                {i18n.language === 'en' ? <h2 className="title" >
                                    {/* Explore Nfts{" "} */}
                                    {t('latest_nfts')}{" "}
                                    <img src="assets/img/icons/title_icon01.png" alt="" />
                                </h2> :
                                    <div className="title">
                                        <h2 className="title" style={{ direction: 'rtl' }} >
                                            {t('latest_nfts') + " "}{" "}

                                        </h2>
                                        <img src="assets/img/icons/title_icon01.png" alt="" />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-5 col-sm-4">
            <div className="section-button text-end">
              <a href="/#" className="btn filter-btn filter-toggle">
                <i className="fi-sr-filter" /> filter
              </a>
            </div>
          </div> */}
                    </div>
                </div>
                <div className="filter-category-wrap">
                    <div className="row">
                        <div className="col-12">
                            <ul className="category-list">
                                <li className="active">
                                    <a href="/index">
                                        <img src="assets/img/icons/cat_001.png" alt="" /> All
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_01.png" alt="" /> Games
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_02.png" alt="" /> Art
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                                        Cards
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_04.png" alt="" /> Music
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                                        Names
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                                        Collectibles
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className="row justify-content-center"
                >

                    {!listedNfts.length ? (
                        <MyNFTLoader numberOfSkeletons={4} />
                    ) : (<Carousel
                        responsive={responsive}
                        customRightArrow={<CustomRightArrow />}
                        customLeftArrow={<CustomLeftArrow />}
                        // containerClass="react-multi-carousel-list"
                        ssr
                    >
                        {   //checks for listedNfts and render items
                            listedNfts?.length &&
                            listedNfts?.slice().sort((a, b) => //sorting based on date
                                new Date(parseInt(b.createdAt.toString()) * 1000) - new Date(parseInt(a.createdAt.toString()) * 1000))
                                .slice(0, 11) //display only 12 items
                                .map((data) => {

                                    return (
                                        <div
                                            className="mx-4" //"col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                            key={data?.mintAddress}
                                        >

                                            <div className="top-collection-item" style={{
                                                maxWidth: 300,
                                                minWidth: 280
                                            }}>
                                                <div className="collection-item-top">
                                                    <ul>
                                                        <li className="avatar">


                                                            <div className="thumb">
                                                                {data?.creators && <Identicon
                                                                    alt=""
                                                                    style={{
                                                                        width: '24px',
                                                                        height: '24px',
                                                                        marginRight: '10px',
                                                                        marginTop: '2px',
                                                                    }}
                                                                    address={data?.creators[0]?.address.toString()} />}
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                By {data?.creators ? data?.creators[0]?.address?.substring(0, 3) + "..." + data?.creators[0]?.address?.slice(-3) : 'Undefined'}
                                                                <br></br>
                                                                <span className="collection-item-date">{"Listed"} {timeSince(new Date(parseInt(data?.createdAt?.toString()) * 1000))}{" ago"}</span>
                                                            </div> </li>


                                                    </ul>
                                                </div>
                                                <div className="collection-item-thumb">
                                                    <Link
                                                        to={`/view-nft/${data?.mintAddress}`}
                                                    >
                                                        <span>
                                                            <img src={data?.image} alt="" />
                                                        </span>
                                                    </Link>
                                                </div>

                                                <div className="collection-item-content">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {data?.name && data?.name?.length > 30 ?
                                                            <Marquee gradient={false}  >
                                                                <h5
                                                                    className="title"
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                    }
                                                                    }>{data?.name}<span></span></h5>
                                                            </Marquee>
                                                            : <h5 className="title"> {data?.name}  </h5>}
                                                        <h5 className="title">
                                                            <span className="price" style={{ fontSize: '16px' }}>
                                                                {data?.price} {data?.currency?.symbol}
                                                            </span>
                                                        </h5>

                                                    </div>
                                                    <div style={{ justifyContent: 'flex-start' }}>
                                                        {data?.description && data?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                                                            <h3 style={{
                                                                color: '#E039FD',
                                                                fontSize: '15px',
                                                                fontWeight: '200',
                                                                marginTop: '10px',
                                                                whiteSpace: 'nowrap',
                                                                // overflow: 'auto',
                                                            }}><span>{data?.description}</span></h3>
                                                        </Marquee> : <h3 style={{
                                                            color: '#E039FD',
                                                            fontSize: '14px',
                                                            fontWeight: '200',
                                                            marginTop: '10px',
                                                            whiteSpace: 'nowrap',
                                                            // overflow: 'auto',
                                                        }}><span>{data?.description}</span></h3>}
                                                    </div>
                                                </div>
                                                <div className="collection-item-bottom">
                                                    <ul>
                                                        <li
                                                            className="bid">
                                                            <Link
                                                                to={`/view-nft/${data?.mintAddress}`}
                                                                className="btn"
                                                            >
                                                                {publicKey
                                                                    ? data?.creators && data?.creators[0]?.address ===
                                                                        publicKey?.toString()
                                                                        ? t('view')
                                                                        : t('buy')
                                                                    : t('buy')}
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                        }</Carousel>
                    )}

                </div>
            </div>
        </section>
    );
};

export default LatestProduct;
