import React, { useContext, useEffect, useState } from "react";
import { buyNFT } from "../../../Api/Solana/buyNft";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./SingleMarket.css";
import { cancelListing } from "../../../Api/Solana/cancelListing";
import { solToUSD } from "../../../contexts/Coingecko";
import SingleMarketLoader from "./SingleMarketLoader";
import { Identicon } from "../Nft/Identicon";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";
import { getTokenTransactions, Mirage } from "@mirrorworld/mirage.core";
import { useWallet } from "@solana/wallet-adapter-react";
// import { Space, Table, Tag } from 'antd/dist/antd';
import { Link } from "react-router-dom";
import RankingPage from "../InnerPages/RankingPage";
import Table from 'react-bootstrap/Table';
import TransactionLoader from "./TransactionLoader";
import { FailedToConnectToBundlrAddressError } from "@metaplex-foundation/js";
import { CreatorsModal } from "./CreatorsModal";

const SingleMarket = ({ data, viewOnly }) => {
  const { listedNfts, auctionHouse, removeListedNft, solPrice } =
    useContext(AuctionHouseContext);

  const { metaplex, connection, publicKey } = useContext(MetaplexContext);
  const { t } = useTranslation();
  const [buying, setBuying] = useState(false);
  const [cancel, setCancel] = useState(false);
  const navigate = useNavigate();
  const [balance, setBalance] = useState([]);
  const { wallet } = useWallet();
  const { id } = useParams()
  const [fallBackNftData, setFallBackNftData] = useState({})
  const [nft, setNft] = useState({})
  const [tradeHistory, setTradeHistory] = useState([
    // {
    //   key: '',
    //   name: '',
    //   transaction_id: '',
    //   transaction_type: '',
    //   time: '',
    //   total_amount: '',
    //   buyer: '',
    //   seller: ''
    // }
  ])
  const [showCreatorModal, setShowCreatorModal] = useState(false);

  const getUserSOLBalance = async () => {
    let balance = connection && await connection?.getBalance(publicKey);

    setBalance(balance / LAMPORTS_PER_SOL);

  };
  const buyNFTAction = () => {


    // test send notofication
    // const body = { pub_key: data?.sellerAddress ? data?.sellerAddress : fallBackNftData?.sellerAddress }
    // axios.get('http://54.90.239.124:3000/user/get_user_profile', {
    //   params: body
    // })
    //   .then(function (response) {
    //     console.log("get user profile", response.data.tempUser, response.data.tempUser["name"], response.data.tempUser["firebase_notif_token_id"]);
    //     const buyerName = response.data.tempUser["name"];
    //     // const body = {
    //     //   "notification": {
    //     //     "title": "NFT sold",
    //     //     // eslint-disable-next-line no-template-curly-in-string
    //     //     "body": "you sold an nft to " + buyerName,
    //     //     "icon": "../../../../public//assets//images//mvp.png",
    //     //     "click_action": "https://nft-plus.me/"
    //     //   },
    //     //   "to": response.data.tempUser["firebase_notif_token_id"]
    //     // }
    //     const userToken = response.data.tempUser["firebase_notif_token_id"]
    //     console.log(userToken, "userToken")
    //     response.data.tempUser["firebase_notif_token_id"] &&
    //       axios.post(
    //         'https://fcm.googleapis.com/fcm/send',

    //         {
    //           'notification': {
    //             'title': 'Nft sold',
    //             // eslint-disable-next-line no-template-curly-in-string
    //             'body': `Your nft was bought by ${buyerName} for ${data?.price ? data?.price : fallBackNftData?.price} SOL`,
    //             'icon': 'firebase-logo.png',
    //             'click_action': 'http://localhost:8081'
    //           },
    //           'to': userToken
    //         },
    //         {
    //           headers: {
    //             'Authorization': 'key=AAAATRDeAtI:APA91bHR3Zx2ggJzgEP-EK9Y36J-qZhPptlg3ZQ7vk6fltiwEE-hOYN5YEw3HUrBbxi-_oJ3heRSKkfsKlc6LLKkF4Nr7OZB1IR-45A9WecPNI9ysn7uFf5sPJQ_uzq8yGNSSK9aUkTR',
    //             'Content-Type': 'application/json'
    //           }
    //         }
    //       )
    //     // fetch("https://fcm.googleapis.com/fcm/send", {
    //     //   body: body,
    //     //   headers: {
    //     //     Authorization: "key=AAAATRDeAtI:APA91bHR3Zx2ggJzgEP-EK9Y36J-qZhPptlg3ZQ7vk6fltiwEE-hOYN5YEw3HUrBbxi-_oJ3heRSKkfsKlc6LLKkF4Nr7OZB1IR-45A9WecPNI9ysn7uFf5sPJQ_uzq8yGNSSK9aUkTR",
    //     //     "Content-Type": "application/json"
    //     //   },
    //     //   method: "POST"
    //     // })
    //     console.log("notifi sent ..............")

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });


    if (publicKey) {
      const buyObj = {
        metaplex: metaplex,
        auctionHouse,
        tradeStateAddress: data?.tradeStateAddress ? data?.tradeStateAddress : fallBackNftData?.tradeStateAddress,
        mintAddress: data?.mintAddress ? data?.mintAddress : fallBackNftData?.mintAddress,
        price: data?.price ? data?.price : fallBackNftData?.price,
        buyerAddress: publicKey,
        sellerAddress: data?.sellerAddress ? new PublicKey(data?.sellerAddress) : new PublicKey(fallBackNftData?.sellerAddress)

      };
      console.log(buyObj, "buyer, seller")
      setBuying(true);
      buyNFT(buyObj)
        .then((res) => {
          if (res && res.purchase) {
            Swal.fire({
              title: "Success!",
              text: "You purchased the NFT Successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });



            removeListedNft(data !== undefined ? data?.tradeStateAddress : fallBackNftData?.tradeStateAddress);
            // navigate(`/my-nfts/${publicKey.toString()}`);
            navigate(`/my-nfts/`);
          }
        })
        .finally(() => {
          setBuying(false);
        });
    } else {
      Swal.fire({
        title: "Error!",
        text: "Wallet is not detected, Please connect your wallet!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const cancelNFTAction = () => {
    if (publicKey) {
      const cancelObj = {
        metaplex: metaplex,
        auctionHouse,
        tradeStateAddress: data?.tradeStateAddress ? data?.tradeStateAddress : fallBackNftData?.tradeStateAddress,
      };
      setCancel(true);
      cancelListing(cancelObj)
        .then((res) => {
          console.log(res, "cancel resp");
          if (res && res.signature) {
            Swal.fire({
              title: "Success!",
              text: "You canceled the NFT listing Successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            removeListedNft(data !== undefined ? data?.tradeStateAddress : fallBackNftData?.tradeStateAddress);
            // navigate(`/my-nfts/${publicKey.toString()}`);
            navigate(`/my-nfts/`);
          }
        })
        .finally(() => {
          setCancel(false);
        });
    } else {
      Swal.fire({
        title: "Error!",
        text: "Wallet is not detected, Please connect your wallet!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };
  const userDontHaveSol = () => {
    Swal.fire({
      title: "Error!",
      text: `Sorry you need to add ${(fallBackNftData?.price - balance).toFixed(2)
        } sol! To make this purchase.`,
      icon: "error",
      confirmButtonText: "Close",
    });
  };

  const getHistory = async () => {
    const transactions = fallBackNftData && await getTokenTransactions(fallBackNftData?.mintAddress, auctionHouse?.address, connection);
    // transaction.sort((a, b) => (b.transaction.createdAt - a.transaction.createdAt))
    transactions && transactions.forEach((transaction, index) => {
      console.table("transaction", transaction,);
      // transaction.sort((a, b) => (b.transaction.createdAt - a.transaction.createdAt))
      setTradeHistory(tradeHistory => [...tradeHistory, {
        key: index,
        name: data?.name ? data?.name : fallBackNftData?.name,
        transaction_id: transaction.metadata,
        transaction_type: transaction.receipt_type,
        time: new Date(transaction.createdAt).toLocaleString(),
        total_amount: transaction.price + " SOL",
        buyer: transaction?.buyer === undefined ? "-" : transaction.buyer,
        seller: transaction?.seller === undefined ? "-" : transaction.seller
      }])

    })
  };

  const fetchNft = () => {
    const filterListing = listedNfts.filter((nft) => nft.mintAddress.toString() === id)
    filterListing.length > 0 && setFallBackNftData(...filterListing)
  }

  const getNft = async () => {
    const nft = await metaplex
      .nfts()
      .findByMint({ mintAddress: new PublicKey(id) })


    setNft(nft)
  }

  useEffect(() => {
    getNft()

    return () => {
      getNft()
    }
  }, [listedNfts])

  useEffect(() => {
    getHistory();

    return () => {
      getHistory();
    }
  }, [data || fallBackNftData])

  useEffect(() => {
    fetchNft()

    return () => {
      fetchNft()
    }
  }, [listedNfts])

  return (fallBackNftData.mintAddress === undefined && viewOnly !== true) ? (
    <SingleMarketLoader nft={nft} />
  ) : (
    <section className="market-single-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="market-single-img">
              <img src={data?.image || fallBackNftData?.image || nft?.json?.image} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="market-single-top" style={{ display: "flex" }}>
              <div className="market-single-title-wrap">
                <h2 className="title">{data?.name || fallBackNftData?.name || nft?.json?.name}</h2>
              </div>
              {viewOnly !== true && <div style={{ display: "flex", flexDirection: "row" }}>
                <h3 style={{ color: "#E039FD", fontWeight: "500" }}>
                  {data?.price || fallBackNftData?.price} <span>SOL</span>
                </h3>{" "}
                <h3
                  className="mx-2"
                  style={{ color: "#59606B", fontWeight: "400" }}
                >
                  {" "}
                  |
                </h3>
                <h3 style={{ color: "#59606B", fontWeight: "400" }}>
                  ${Math.round((data?.price || fallBackNftData?.price) * solPrice * 100) / 100}
                </h3>
              </div>}

            </div>

            <div className="highest-bid-wrap" style={{ "marginBottom": "20px" }}>

              <div>
                <h5>{t("desc")}</h5>
              </div>
              <div className="market-single-content">
                <p>{data?.description || fallBackNftData?.description || nft?.json?.description}</p>
              </div>
            </div>

            {viewOnly ? <></> :
              <div className="row">
                <div className="col-sm-6">
                  <div className="market-single-creator">


                    <div className="thumb">
                      {((data?.creators[0] || fallBackNftData?.creators[0]) &&
                        <Identicon
                          alt=""
                          style={{
                            borderRadius: "50%",
                            height: "52px",
                            width: "52px",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                          address={data?.creators[0]?.address.toString() || fallBackNftData?.creators[0]?.address.toString()}
                        />)
                      }
                    </div>


                    <div className="info">
                      <span>
                        {(data?.creators?.length > 1 || fallBackNftData?.creators?.length > 1) ? t('creators') : t("creator")}{" "}
                        {/* {(data?.creators.map((creator) => ( */}

                        <div>
                          <ul>
                            <li>
                              Created by{" "}
                              <a
                                style={{
                                  fontStyle: "italic",
                                  color: "#E039FD",
                                }}
                              >
                                {(data?.creators[0]?.address?.toString().slice(0, 4) || fallBackNftData?.creators[0]?.address?.toString().slice(0, 4)) +
                                  "...." +
                                  (data?.creators[0]?.address?.toString().slice(41, 44) || fallBackNftData?.creators[0]?.address?.toString().slice(41, 44))}
                              </a>
                            </li>
                            <li>
                              Share{" "}
                              <a
                                style={{
                                  fontStyle: "italic",
                                  color: "#E039FD",
                                }}
                              >
                                {data?.creators[0]?.share || fallBackNftData?.creators[0]?.share} %
                              </a>
                            </li>
                          </ul>
                        </div>
                        {console.log('is there data', data?.creators === undefined)}
                        {console.log('is there fallback data', fallBackNftData?.creators === undefined)}
                        {(data?.creators?.length > 1 || fallBackNftData?.creators?.length > 1) ? <button
                          onClick={() => setShowCreatorModal(true)}
                          style={{ border: 'none', background: 'none' }}
                        >
                          <span style={{
                            fontStyle: "italic",
                            color: "#E039FD",
                          }}>Show all</span>
                        </button> : <></>}
                        {/* // || 
                        // (fallBackNftData?.creators.map((creator) => (
                        //   <div>
                        //     <ul>
                        //       <li>
                        //         Created by{" "}
                        //         <a
                        //           style={{
                        //             fontStyle: "italic",
                        //             color: "#E039FD",
                        //           }}
                        //         >
                        //           {creator.address.slice(0, 4) +
                        //             "...." +
                        //             creator.address.slice(41, 44)}
                        //         </a>
                        //       </li>
                        //       <li>
                        //         Share{" "}
                        //         <a
                        //           style={{
                        //             fontStyle: "italic",
                        //             color: "#E039FD",
                        //           }}
                        //         >
                        //           {creator.share} %
                        //         </a>
                        //       </li>
                        //     </ul>
                            {/* <p>verified: {creators.verified}</p> */}
                        {/* </div>
                        ))) */}
                      </span>
                    </div>
                  </div>

                  {/* </div> */}
                </div>
                <div className="col-sm-6">
                  <div className="market-single-creator">
                    <div
                      className="info"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >

                        <div
                          className="info"
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <span>
                            <div>
                              <ul>
                                <li>
                                  Owned by{" "}

                                  <Link style={{
                                    fontStyle: "italic",
                                    color: "#E039FD",
                                  }} to={`/author-profile/${data?.sellerAddress ? data?.sellerAddress.toString() : fallBackNftData?.sellerAddress}`} state={data?.sellerAddress ? data?.sellerAddress.toString() : fallBackNftData?.sellerAddress}>

                                    {`${data?.sellerAddress ? data?.sellerAddress.slice(0, 4) : fallBackNftData?.sellerAddress.slice(0, 4)} 
                                    ....
                                    ${data?.sellerAddress ? data?.sellerAddress.slice(41, 444) : fallBackNftData?.sellerAddress.slice(41, 44)}`}
                                  </Link>

                                </li>

                                <li>
                                  Royalty:{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      color: "#E039FD",
                                    }}
                                  >
                                    {" "}
                                    {data?.sellerFeeBasisPoints || fallBackNftData?.sellerFeeBasisPoints} %
                                  </span>{" "}
                                </li>
                                <li>
                                  Marketplace Fee:{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      color: "#E039FD",
                                    }}
                                  >
                                    {" "}
                                    {5} %
                                  </span>{" "}
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CreatorsModal
                  showCreatorModal={showCreatorModal}
                  setShowCreatorModal={setShowCreatorModal}
                  data={data || fallBackNftData}
                />
              </div>
            }


            {viewOnly ? <></> : <button
              onClick={getUserSOLBalance() &&
                publicKey ? (data?.sellerAddress ? data?.sellerAddress : fallBackNftData?.sellerAddress) !== publicKey?.toString()
                ? fallBackNftData?.price <= balance
                  ? buyNFTAction
                  : userDontHaveSol
                : cancelNFTAction
                : buyNFTAction

              }
              className="place-bid-btn"
            >

              {(data?.sellerAddress ? data?.sellerAddress : fallBackNftData?.sellerAddress) !== publicKey?.toString()
                ? buying
                  ? t("buying")
                  : t("buy")
                : cancel
                  ? t("delisting")
                  : t("delist")

              }
            </button>}
          </div>
          {/* IN PROGRESS */}
          {viewOnly ? <></> :
            tradeHistory?.length === 0 ?
              <TransactionLoader /> :
              <div className="col-xl-12 col-lg-12">
                <div //className="activity-table-wrap" 
                  style={{ marginTop: '40px' }}>
                  <div style={{
                    display: 'block',
                    alignItems: 'flex-start',
                    // textAlign: 'center'
                  }}>
                    <h4 class="title">Transaction History</h4>
                  </div>
                  {/* <Table className="table activity-table" columns={columns} dataSource={tradeHistory} style={{ backgroundColor: "transparent", marginTop: "40px", color: "white" }} bordered={true} /> */}
                  <div className="tab-content" id="myTabContent" >

                    <Table className="table activity-table" responsive>
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Transaction Id</th>
                          <th scope="col">Transaction Type</th>
                          <th scope="col">Time</th>
                          <th scope="col">Total Amount</th>
                          <th scope="col">Buyer</th>
                          <th scope="col" class="time">Seller</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tradeHistory.length > 0 && tradeHistory.map((item) => (
                          <tr key={item.key}>
                            <td>{item.name}</td>
                            <td>
                              <a href={`https://solana.fm/address/${item.transaction_id}?cluster=mainnet-qn1`} target="_blank" rel="noreferrer">
                                {item?.transaction_id?.toBase58().slice(0, 4) + "..." + item?.transaction_id?.toBase58().slice(40)}
                              </a>
                            </td>
                            <td>{item.transaction_type}</td>
                            <td>{item.time}</td>
                            <td>{item.total_amount}</td>
                            <td>{item.buyer !== "-" ?
                              <a href={`https://solana.fm/address/${item.buyer}?cluster=mainnet-qn1`} target="_blank" rel="noreferrer">
                                {item?.buyer?.toBase58().slice(0, 4) + "..." + item?.buyer?.toBase58().slice(40)}
                              </a> : "-"}
                            </td>
                            <td>{item.seller !== "-" ?
                              <a href={`https://solana.fm/address/${item.seller}?cluster=mainnet-qn1`} target="_blank" rel="noreferrer">
                                {item?.seller?.toBase58().slice(0, 4) + "..." + item?.seller?.toBase58().slice(40)}
                              </a> : "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>}
        </div>
      </div>

    </section>
  );
};

export default SingleMarket;

