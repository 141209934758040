import { myNftAdapter } from "../../Adapter/Solana/nft";
import { toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export const fetchUserNft = async (config) => {
  // console.log('config user: ', config);
  const myNfts = await config.metaplex
    .nfts()
    .findAllByOwner({
      owner: config.publicKey,
    })


  const nfts = []
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      const jsonNft = await config.metaplex
        .nfts()
        .load({ metadata: nft });
      nfts.push({ ...jsonNft })
    }
  } catch (error) {
    console.log(error);
  }
  return myNftAdapter(nfts);
};

export const fetchSellerNft = async (config, publicKey) => {


  console.log('user pubkey', publicKey);
  const myNfts = await config.metaplex
    .nfts()
    .findAllByOwner({
      owner: publicKey,//'2eVAYwxrfZiPBQYVpjUKSgXPCLWEaACqaeo6j7ijK48G',
    })



  const nfts = []
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      // console.log("nft", nft);
      const jsonNft = await config.metaplex
        .nfts()
        .load({ metadata: nft });
      nfts.push({ ...jsonNft })
      // console.log("nft from owner pubkey ", jsonNft);

    }

  } catch (error) {

  }



  return myNftAdapter(nfts);
};

export const fetchCollectionNft = async (config) => {
  // console.log(config.publicKey);
  const myNfts = await config.metaplex
    .nfts()
    .findAllByOwner({
      owner: config.address,
    })




  const nfts = []
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      // console.log("nft", nft);
      const jsonNft = await config.metaplex
        .nfts()
        .load({ metadata: nft });
      nfts.push({ ...jsonNft })
      // console.log("nft from owner pubkey ", jsonNft);

    }

  } catch (error) {

  }



  return myNftAdapter(nfts);
};

export const createNft = async (config, data) => {

  try {
    console.log("Minting NFT...");
    const steps = ['1', '2', '3']


    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: 'Next >',

      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' },
      //   backdrop: `
      //   rgba(0,0,123,0.4)
      //   url("../img/others/loading gif.gif")
      //   center
      //   no-repeat
      // `

    })

    const updatedMetaData = {
      ...data,
      image: await toMetaplexFileFromBrowser(data.image),
    };


    await Queue.fire({
      title: 'Signature approvals.',
      text: 'Firstly, we will need two signature approvals.',
      currentProgressStep: 0,
      allowOutsideClick: false,
      closed: false,

    })

    const { uri } = await config.metaplex
      .nfts()
      .uploadMetadata(updatedMetaData)


    await Queue.fire({
      title: 'Transaction Approval',
      text: 'Secondly, we will need your transaction approval',
      currentProgressStep: 1,
      allowOutsideClick: false,
      closed: false,
    })

    const { nft } = await config.metaplex
      .nfts()
      .create({
        uri: uri,
        isMutable: true,
        name: data.name,
        symbol: data.symbol,
        sellerFeeBasisPoints: data.seller_fee_basis_points,
      })


    console.log("NFT Minted");

    await Queue.fire({
      icon: "success",
      title: 'Congratulations!',
      text: "Your NFT has been successfully created",
      currentProgressStep: 2,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      closed: false,
    })
    return nft;
  } catch (err) {
    console.log(err);
    Swal.fire(
      {
        icon: 'error',
        title: 'Failed to create NFT',
        text: `${err}`,
        confirmButtonText: 'OK',

      }
    )
  }
};
