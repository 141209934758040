import React, { useState, ToolTip } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';


const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: '20%',//'100px',
    left: '10%',
    right: '10%',
    bottom: '20%',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px'
  }
};


const NFTForm = ({ minting, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [royalty, setRoyalty] = useState(8);

  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const royaltyChanged = (event) => {
    setRoyalty(event.target.value);
  };

  const onSubmitForm = (data) => {
    // setOpenModal(true);
    const formated_data = {
      ...data,
      royalty,
    };
    onSubmit(formated_data);
  };

  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmitForm)}
      className="create-item-form"
      style={{ maxWidth: "380px", margin: "auto", minHeight: 650 }}
    >
      <div className="form-grp">
        <label htmlFor="name">{t('nft_name')}</label>
        <input id="name" {...register("name", { required: true })} maxlength="32" />
        {errors.name && (
          <span className="input-error-text">{t('name_req')}</span>
        )}
      </div>
      <div className="form-grp">
        <ReactTooltip id="first-tip" effect="solid" multiline style={{ fontSize: 14, color: '#59606B' }} place={i18n.language === 'en' ? "right" : "left"} />
        <label htmlFor="symbol">{t('symbol')}{" "}
          <i className="fas fa-question-circle"
            data-tip={t('symbol_tip')}
            data-for="first-tip"
            place={i18n.language === 'en' ? "right" : "left"}
            style={{ fontSize: 14, color: '#59606B' }} />

        </label>
        <input id="symbol" {...register("symbol", { required: true })} maxlength="10" />
        {errors.symbol && (
          <span className="input-error-text">{t('symbol_req')}</span>
        )}
      </div>
      <div className="form-grp">
        <ReactTooltip className="row justify-content-center" id="second-tip" effect="solid" multiline bodyMode place={i18n.language === 'en' ? "right" : "left"} style={{ fontFamily: 'Poppins' }} />
        <label htmlFor="description">{t('desc')}{" "}
          <i className="fas fa-question-circle" data-tip={t('desc_tip')} data-for="second-tip" place={i18n.language === 'en' ? "right" : "left"} style={{ fontSize: 14, color: '#59606B' }} />
        </label>
        <input
          id="description"
          {...register("description", { required: true })}
        />
        {errors.description && (
          <span className="input-error-text">{t('desc_req')}</span>
        )}
      </div>
      <div className="form-grp">
        <ReactTooltip id="third-tip" effect="solid" multiline bodyMode place={i18n.language === 'en' ? "right" : "left"} style={{ fontFamily: 'Poppins' }} />
        <label htmlFor="royalty">{t('royalty')} {royalty}%{" "}
          <i className="fas fa-question-circle" data-tip={t('royalty_tip')} data-for="third-tip" place={i18n.language === 'en' ? "right" : "left"} style={{ fontSize: 14, color: '#59606B' }} />
        </label>
        <Form.Range
          value={royalty}
          onChange={royaltyChanged}
          id="royalty-slider"
          style={{
            borderColor:
              royalty > 75
                ? "red"
                : royalty > 50
                  ? "orange" : "#2A2547",
            // : royalty > 25
            //   ? "blue"
            //   : "green",
            backgroundColor: "#2A2547",
            //royalty > 30 ? "red" : "green"
            // "linear-gradient(179.69deg, rgba(29, 25, 51, 0.46)14.17%, rgba(29, 25, 51, 0.27)99.73%)",

          }}
        />
        {royalty > 30 ? (
          <span className="input-error-text">
            {t('royalty_alert')}
          </span>
        ) : (
          ""
        )}
      </div>

      {openModal ? <div></div> : <button className="btn" type="submit" disabled={minting}>
        {minting ? (
          <div className="d-flex align-items-center">
            <span className="mr-2">Minting in Progress, Please wait...</span>

            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          t('create')
        )}
      </button>}
      {/* <div> */}
      {/* <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customStyles}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> */}
      {/* <Stepper
            activeStep={activeStep}
            connectorStyleConfig={{ size: 2 }}
            styleConfig={{ size: 50, activeBgColor: 'dodgerblue', completedBgColor: 'limegreen' }}

            step={[{ label: "Signature Approval: We will need your signature approval" }, { label: "Transaction Approval: We will need your transaction approval" }, { label: 'Step 3' }]}> */}
      {/* <Step label= /> */}


      {/* <Step label="Transaction Approval: We will need your transaction approval" /> */}


      {/* </Stepper> */}
      {/* <Spinner animation="border" variant="primary" /> */}
      {/* <button className="btn" onClick={() => setOpenModal(false)} disabled={minting}>

            <div className="d-flex align-items-center">
              <span className="mr-2">Cancel</span>


            </div>

          </button> */}

      {/* </div>

      </Modal> */}
      {/* </div> */}
    </form >
  );
};

export default NFTForm;