/* eslint-disable */
import { createContext } from "react";
import { Metaplex,/* bundlrStorage,*/ walletAdapterIdentity } from "@metaplex-foundation/js";
import { nftStorage } from "@metaplex-foundation/js-plugin-nft-storage";
import { Connection } from "@solana/web3.js";//clusterApiUrl
import { useWallet } from "@solana/wallet-adapter-react";

export const MetaplexContext = createContext();

const NFTPlusMainnetProduction = "https://floral-cool-sanctuary.solana-mainnet.quiknode.pro/" //This is paid. Use this only for production on NFTPlus.
const PublicRPCDevnet = "https://devnet.genesysgo.net/" //will be deprecated at any moment
const PublicRPCMainnet0 = "https://ssc-dao.genesysgo.net/" //will be deprecated at any moment
const PublicRPCMainnet1 = "https://solana-api.projectserum.com/" //This is reliable, for now

const MetaplexContextProvider = ({ children }) => {

  const connection = new Connection(NFTPlusMainnetProduction);
  const wallet = useWallet();
  const { publicKey } = wallet;
  const metaplex = Metaplex.make(connection)

  // For uploading nft metadata to bundlr

  //   .use(walletAdapterIdentity(wallet))
  //   .use(bundlrStorage({ address: "https://devnet.bundlr.network" }));

  metaplex.use(walletAdapterIdentity(wallet)).use(nftStorage({ token: process.env.NFT_STORAGE_API, }));
  return (
    <MetaplexContext.Provider value={{ metaplex, publicKey, connection }}>
      {children}
    </MetaplexContext.Provider>
  );
};

export default MetaplexContextProvider;
