import { PublicKey } from "@solana/web3.js";
import { sol } from "@metaplex-foundation/js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useWallet } from "@solana/wallet-adapter-react";

export const buyNFT = async ({
  metaplex,
  auctionHouse,
  tradeStateAddress,
  mintAddress,
  price,
  buyerAddress,
  sellerAddress
}) => {
  try {
    const steps = ["1", "2"];
    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: "Next >",

      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
      allowOutsideClick: false,
    });

    await Queue.fire({
      title: "Transaction Approvals",
      text: "There will be two transaction requests that require approval to proceed with your purchase",
      currentProgressStep: 0,
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: "swal2-noanimation" },
      allowOutsideClick: false,
      closed: false,
    });

    const listing = await metaplex
      .auctionHouse().findListingByTradeState({
        tradeStateAddress: tradeStateAddress,
        auctionHouse
      });
    if (listing.purchaseReceiptAddress === null && listing.canceledAt === null) {

      const { bid, buyerTradeState, receipt } = await metaplex
        .auctionHouse()
        .bid({
          auctionHouse,
          mintAccount: mintAddress,
          price: sol(price),
          printReceipt: true,
        })

      // replace the receipt to execute sale
      // const bid = await metaplex
      //   .auctionHouse()
      //   .findBidByReceipt({
      //     receiptAddress: new PublicKey("AoU6osGHxW2ENPk35ZaNXawEEP45KthURJfKg2yDvfu8"),
      //     auctionHouse
      //   }
      //   )


      console.log(bid, listing.price.basisPoints.toNumber());
      try {
        const tx = await metaplex
          .auctionHouse()
          .executeSale({ auctionHouse, listing, bid })

        return tx;
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Transaction failed.",
          text: "Please try again. The Solana TPS was globally slow. ",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } else {
      Swal.fire("Invalid Listing")
    }




    await Queue.fire({
      icon: "success",
      title: "Complete",
      text: "All steps completed!",
      currentProgressStep: 1,
      confirmButtonText: "OK",
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: "swal2-noanimation" },
      allowOutsideClick: false,
      closed: false,
      // hideClass: { backdrop: 'swal2-noanimation' }
    });
  } catch (error) {
    console.log(error);
  }
};
